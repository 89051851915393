import React from "react";
import HeroImage from "../../images/teedevLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faInstagram, faLine } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <footer className="footer border z-10 border-t-[#33353F] border-l-transparent border-r-transparent text-white">
      <div className="p-12 flex justify-between items-center">
        <div className="flex flex-col justify-center items-center">
          {/* <div className="flex justify-items-center items-center">
            <img src={HeroImage} alt="hero image" width={100} height={100} />
            <span className="font-english">TEEDEV</span>
          </div> */}
          <div className="flex flex-col justify-center items-start">
            <div>
              <span className="font-thai">บริการรับทำเว็บไซต์ ( Website ) ตามความต้องการของลูกค้า ครบทุกรูปแบบ </span>
            </div>
            <div className="flex mt-5">
              <span className="font-thai">จันทร์-อาทิตย์ 9.30-00.00น. </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end justify-center">
        <p className="text-slate-300">@2024 T2E-TECH , All rights reserved.</p>
        <div className="flex row  mt-5">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="font-thai mx-2">
                <FontAwesomeIcon icon={faFacebook} size="lg" />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="font-thai mx-2">
                <FontAwesomeIcon icon={faTwitter} size="lg" />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="font-thai mx-2">
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </a>
              <a href="https://line.me" target="_blank" rel="noopener noreferrer" className="font-thai mx-2">
                <FontAwesomeIcon icon={faLine} size="lg" />
              </a>
            </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

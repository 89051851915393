import React from "react";
import { motion } from "framer-motion";
import { TypeAnimation } from "react-type-animation";
import HeroImage from "../../images/logo_t2e.png";
import Advice from "../../images/advice.svg";
import Computer from "../../images/customize-computer.svg";
import Resonsive from "../../images/responsive.svg";
import "../base/custom.css"
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const HeroSection = () => {
    return (
      <>
      <Helmet>
          <title>T2ETECH - รับพัฒนาและออกแบบเว็บไซต์ทุกรูปแบบ</title>
          <meta name="description" content="TEEDEV brings your ideas to life. เรารับพัฒนาเว็บไซต์และแอปพลิเคชันทุกรูปแบบ" />
          <meta name="keywords" content="web development, mobile development, UI/UX design, รับทำเว็บไซต์, รับพัฒนาเว็บไซต์ ,รับทำแอปพลิเคชัน" />
      </Helmet>
      <section className="lg:py-16 mt-24" id="about">
        <div className="grid grid-cols-1 sm:grid-cols-12">
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="col-span-8 place-self-center text-center sm:text-left justify-self-start"
          >
           <h1 className=" mb-4 text-3xl sm:text-5xl lg:text-5xl font-extrabold font-english text-transparent bg-clip-text bg-gradient-to-r from-secondary1 to-secondary2">
                        T2E-TECH brings your<br /> 
                        ideas to life.
            </h1>
            <h1 className="text-white mb-4 text-3xl sm:text-5xl lg:text-5xl lg:leading-normal font-extrabold font-thai">
              <TypeAnimation
                sequence={[
                  "บริการรับสร้างเว็บไซต์",
                  1000,
                  "ออกแบบเว็บไซต์",
                  1000,
                  "เว็บไซต์รองรับ SEO ติดอันดับ Google",
                  1000,
                ]}
                wrapper="span"
                speed={50}
                repeat={Infinity}
              />
            </h1>
            <p className="text-white text-base sm:text-lg mb-6 lg:text-4xl font-extrabold  font-thai" >
                รับพัฒนาและออกแบบเว็บไซต์ทุกรูปแบบ
            </p>
            <p className="text-[#ADB7BE] text-base sm:text-lg mb-6 lg:text-xl font-thai" >
              รับทำโปรเจคนักศึกษา และพัฒนา Web Application คุยง่าย ราคาเป็นกันเอง
            </p>
            <div className="font-english mb-4">
            <a href="https://lin.ee/yeJcQYf"  target="_blank" className="inline-block transform hover:translate-y-2 transition-transform"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" height="36" border="0"/></a>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0 }}
            className="col-span-4 place-self-center mt-4 lg:mt-0 transition duration-500"
          >
            <div className="rounded-full  w-[250px] h-[250px] lg:w-[350px] lg:h-[350px] mt-5 mb-5 lg:mt-0 lg:mb-0 relative">
              <div className="absolute rounded-full  w-[350px] h-[350px] blur-[150px] z-1  lg:bg-gradient-to-tr lg:from-secondary5/50 lg:to-secondary6/50 ">

              </div>
              <div
                  className="absolute rounded-md text-white font-bold text-center z-10 w-[230px] h-[70px] top-28 right-24 lg:w-[220px] lg:h-[70px] transform  lg:top-1/2  lg:right-72  bg-white/5 border 
              border-white/10 overflow-hidden animate-wiggle"
                >
                <div className="flex p-2">
                <div className="bg-gradient-to-tr from-secondary5/50 to-secondary6/50  rounded-md p-2 flex items-center justify-center">
                  <img src={Computer} width={60} height={45} className="red-filter" />
                </div>
                <p className="font-thai">ออกแบบเว็บไซต์ตามสไตล์ธุรกิจของคุณ</p>
                </div>
              </div>

              <div
                  className="absolute rounded-md text-white font-bold text-center z-10 w-[160px] h-[70px] top-56  left-24 lg:w-[160px] lg:h-[75px] transform  lg:top-72  lg:left-48  bg-white/5 border 
              border-white/10 overflow-hidden animate-wiggle"
                >
                <div className="flex p-2">
                <div className="bg-gradient-to-tr from-secondary5/50 to-secondary6/50  rounded-md p-2 flex items-center justify-center">
                  <img src={Advice} width={60} height={45} className="red-filter" />
                </div>
                <p className="font-thai">บริการให้คำปรึกษาฟรี</p>
                </div>
              </div>


              <div
                  className="absolute rounded-md text-white text-center font-bold z-10 w-[250px] h-[70px] left-16 bottom-52 lg:w-[250px] lg:h-[70px] transform  lg:bottom-48  lg:left-64  bg-white/5 border 
              border-white/10 overflow-hidden animate-wiggle"
                >
                <div className="flex p-2">
                <div className="bg-gradient-to-tr from-secondary5/50 to-secondary6/50  rounded-md p-2 flex items-center justify-center">
                  <img src={Resonsive} width={60} height={45} className="red-filter" />
                </div>
                <p className="font-thai">ตอบโจทย์ธุรกิจทุกประเภท
                และมีเทคโนโลยีที่ทันสมัย</p>
                </div>
              </div>

              
              <img
                src={HeroImage}
                alt="hero image"
                className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-full h-full "
              />
            </div>
          </motion.div>
        </div>
      </section>
      </>
    );
  };

  export default HeroSection;
  
import React, { useState } from 'react'
import NavLink from './NavLink'
import { motion } from 'framer-motion'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import MenuOverlay from './MenuOverlay'
import { Link } from 'react-router-dom'

function CardDetail() {
  return (
    <>
      <div data-aos="flip-up" class="max-w-xl mx-auto text-center mt-24 " id="services">
        <motion.div className="text-5xl text-white font-medium mt-[10px] text-center mb-[15px] font-english">
          Service
        </motion.div>
        <p class="leading-relaxed  text-[20px] font-thai text-gray-200">
          เราคือผู้เชี่ยวชาญด้านการรับทำโปรเจคนักศึกษาและเว็บไซต์บริษัท
          พร้อมยินดีให้คำปรึกษาเกี่ยวกับเว็บไซต์
          ครบทุกความต้องการทั้งในด้านการพัฒนาและการดูแลรักษา
        </p>
      </div>
      <div class="grid md:grid-cols-3 gap-14 md:gap-5 mt-20">
        <div
          data-aos="fade-up"
          class="border-2 border-gray-400 rounded-lg shadow-xl p-6 text-center transition-all duration-500 hover:bg-white/5 hover:backdrop-blur-sm hover:-translate-y-6"
        >
          <div
            style={{ background: '#5B72EE' }}
            class="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
          >
            <svg
              class="w-6 h-6 text-white"
              viewBox="0 0 33 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.75 23H8.25V28.75H24.75V23ZM32.3984 9.43359L23.9852 0.628906C23.5984 0.224609 23.0742 0 22.5242 0H22V11.5H33V10.952C33 10.3859 32.7852 9.83789 32.3984 9.43359ZM19.25 12.2188V0H2.0625C0.919531 0 0 0.961328 0 2.15625V43.8438C0 45.0387 0.919531 46 2.0625 46H30.9375C32.0805 46 33 45.0387 33 43.8438V14.375H21.3125C20.1781 14.375 19.25 13.4047 19.25 12.2188ZM5.5 6.46875C5.5 6.07164 5.80766 5.75 6.1875 5.75H13.0625C13.4423 5.75 13.75 6.07164 13.75 6.46875V7.90625C13.75 8.30336 13.4423 8.625 13.0625 8.625H6.1875C5.80766 8.625 5.5 8.30336 5.5 7.90625V6.46875ZM5.5 12.2188C5.5 11.8216 5.80766 11.5 6.1875 11.5H13.0625C13.4423 11.5 13.75 11.8216 13.75 12.2188V13.6562C13.75 14.0534 13.4423 14.375 13.0625 14.375H6.1875C5.80766 14.375 5.5 14.0534 5.5 13.6562V12.2188ZM27.5 39.5312C27.5 39.9284 27.1923 40.25 26.8125 40.25H19.9375C19.5577 40.25 19.25 39.9284 19.25 39.5312V38.0938C19.25 37.6966 19.5577 37.375 19.9375 37.375H26.8125C27.1923 37.375 27.5 37.6966 27.5 38.0938V39.5312ZM27.5 21.5625V30.1875C27.5 30.9817 26.8847 31.625 26.125 31.625H6.875C6.11531 31.625 5.5 30.9817 5.5 30.1875V21.5625C5.5 20.7683 6.11531 20.125 6.875 20.125H26.125C26.8847 20.125 27.5 20.7683 27.5 21.5625Z"
                fill="white"
              />
            </svg>
          </div>
          <h1 class="font-medium text-xl mb-3 lg:px-14 text-darken text-transparent bg-clip-text bg-gradient-to-r from-secondary1 to-secondary2">
            การทำโปรเจคนักศึกษา
          </h1>
          <p class="px-4 text-gray-300">
            เรามีบริการรับทำโปรเจคนักศึกษาในทุกสาขาวิชา ทั้งการเขียนโปรแกรม
            การสร้างเว็บไซต์ สร้างแอปพลิเคชั่นบนมือถือ และอื่นๆ
            สามารถเลือกภาษาที่ใช้พัฒนาเว็บไซต์ได้ตามความต้องการ อย่างเช่น React
            , React Native , Angular , Nodejs , Php , Spring Boot , MySql ,
            PostgreSQL
            ทางเรามีทีมงานที่เชียวชาญสามาถทำให้โปรเจคจบออกมาได้สมบูรณ์ตามที่ลูกค้าต้องการ
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="150"
          class="border-2 border-gray-400 rounded-lg shadow-xl p-6 text-center transition-all duration-500 hover:bg-white/5 hover:backdrop-blur-sm hover:-translate-y-6"
        >
          <div
            style={{ background: '#F48C06' }}
            class="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
          >
            <svg
              class="w-6 h-6 text-white"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 0C11.0532 0 10.2857 0.767511 10.2857 1.71432V5.14285H13.7142V1.71432C13.7142 0.767511 12.9467 0 12 0Z"
                fill="#F5F5FC"
              />
              <path
                d="M36 0C35.0532 0 34.2856 0.767511 34.2856 1.71432V5.14285H37.7142V1.71432C37.7143 0.767511 36.9468 0 36 0Z"
                fill="#F5F5FC"
              />
              <path
                d="M42.8571 5.14282H37.7143V12C37.7143 12.9468 36.9468 13.7143 36 13.7143C35.0532 13.7143 34.2857 12.9468 34.2857 12V5.14282H13.7142V12C13.7142 12.9468 12.9467 13.7143 11.9999 13.7143C11.0531 13.7143 10.2856 12.9468 10.2856 12V5.14282H5.14285C2.30253 5.14282 0 7.44535 0 10.2857V42.8571C0 45.6974 2.30253 48 5.14285 48H42.8571C45.6975 48 48 45.6974 48 42.8571V10.2857C48 7.44535 45.6975 5.14282 42.8571 5.14282ZM44.5714 42.8571C44.5714 43.8039 43.8039 44.5714 42.857 44.5714H5.14285C4.19605 44.5714 3.42854 43.8039 3.42854 42.8571V20.5714H44.5714V42.8571Z"
                fill="#F5F5FC"
              />
              <path
                d="M13.7142 23.9999H10.2857C9.33889 23.9999 8.57138 24.7674 8.57138 25.7142C8.57138 26.661 9.33889 27.4285 10.2857 27.4285H13.7142C14.661 27.4285 15.4285 26.661 15.4285 25.7142C15.4285 24.7674 14.661 23.9999 13.7142 23.9999Z"
                fill="#F5F5FC"
              />
              <path
                d="M25.7143 23.9999H22.2857C21.3389 23.9999 20.5714 24.7674 20.5714 25.7142C20.5714 26.661 21.3389 27.4285 22.2857 27.4285H25.7143C26.6611 27.4285 27.4286 26.661 27.4286 25.7142C27.4286 24.7674 26.6611 23.9999 25.7143 23.9999Z"
                fill="#F5F5FC"
              />
              <path
                d="M37.7142 23.9999H34.2856C33.3388 23.9999 32.5713 24.7674 32.5713 25.7142C32.5713 26.661 33.3388 27.4285 34.2856 27.4285H37.7142C38.661 27.4285 39.4285 26.661 39.4285 25.7142C39.4285 24.7674 38.661 23.9999 37.7142 23.9999Z"
                fill="#F5F5FC"
              />
            </svg>
          </div>
          <h1 class="font-medium text-xl mb-3 lg:px-14 text-darken text-transparent bg-clip-text bg-gradient-to-r from-secondary1 to-secondary2">
            การให้คำปรึกษา
          </h1>
          <p class="px-4 text-gray-300">
            สำหรับการให้คำปรึกษาเกี่ยวกับโปรเจคจบนักศึกษานั้นทางเราสามารถให้คำปรึกษาได้
            24 ชม.
            <br></br>
            ลูกค้าสามารถสอบถามข้อมูลเพื่อนำข้อมูลไปใช้ในการทำเล่มได้ครบทุกบท
            <br></br>
            ลูกค้าสามารถสามารถสอบถามข้อมูลเกี่ยวกับการทำงานของเว็บไซต์ โปรแกรม
            และ อื่นๆ และทางเรายังมีบริการอธิบาย สอน Technical อธิบาย code
            ภาพรวมของของโปรเจค
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="300"
          class="border-2 border-gray-400 rounded-lg shadow-xl p-6 text-center transition-all duration-500 hover:bg-white/5 hover:backdrop-blur-sm hover:-translate-y-6"
        >
          <div
            style={{ background: '#F48C06' }}
            class="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
          >
            <svg
              class="w-6 h-6 text-white"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M37.2312 18.6328L19.2859 0.554688C19.1852 0.448242 19.0605 0.364258 18.9205 0.308594C18.7805 0.253906 18.6289 0.228516 18.4766 0.234375H18.4774C18.3242 0.228516 18.1727 0.253906 18.0327 0.308594C17.8927 0.364258 17.768 0.448242 17.6674 0.554688L0.721094 18.6328C0.537109 18.8281 0.4375 19.086 0.4375 19.3555C0.4375 19.625 0.537109 19.8828 0.721094 20.0781L18.6674 38.1562C18.768 38.2627 18.8927 38.3467 19.0327 38.4023C19.1727 38.458 19.3242 38.4834 19.4774 38.4775H19.4766C19.6297 38.4834 19.7813 38.458 19.9213 38.4023C20.0613 38.3467 20.186 38.2627 20.2867 38.1562L38.2328 20.0781C38.4168 19.8828 38.5164 19.625 38.5164 19.3555C38.5164 19.086 38.4168 18.8281 38.2328 18.6328H37.2312ZM19.4766 30.8047L10.1406 21.3984L14.2547 17.2852L19.4766 22.5078L29.2656 12.7188L33.3797 16.832L19.4766 30.8047Z"
                fill="white"
              />
            </svg>
          </div>
          <h1 class="font-medium text-xl mb-3 lg:px-14 text-darken text-transparent bg-clip-text bg-gradient-to-r from-secondary1 to-secondary2">
            การดูแล
          </h1>
          <p class="px-4 text-gray-300">
            ทางเรายินดีดูแลโปรเจคจบของลูกค้าจนจบวันพรีเซ้นงาน
            รวมถึงติดตั้งตัวโปรเจคให้ในเครื่องคอมพิวเตอร์ของลูกค้า
            และในส่วนของราคาในการทำโปรเจคจบไม่ว่าเป็นการทำ เว็บไซต์ ,
            แอปพลิเคชั่นบนมือถือ , โปรแกรมต่างๆ
            ลูกค้าจะได้รับสิ่งที่ดีที่สุดเมื่อใช้บริการสร้างเว็บไซต
          </p>
        </div>
      </div>
    </>
  )
}

export default CardDetail

import { Link as ScrollLink } from "react-scroll";
const NavLink = ({ to, title }) => {
    return (
      <ScrollLink
      to={to}
      smooth={true}
      duration={500} 
      offset={-70}  
        className="block py-2 pl-3 pr-4 text-[#ADB7BE] sm:text-xl rounded md:p-0 hover:text-white cursor-pointer"
      >
        {title}
      </ScrollLink>
    );
  };
  
  export default NavLink;
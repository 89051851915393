import React from 'react'
import {motion} from 'framer-motion'
import { useInView } from 'react-intersection-observer'



const SkillDataProvider = ({ src, width, height, index} ) => {
    const {ref, inView} = useInView({
        triggerOnce: true
    })

    const imageVariants = {
        hidden: {opacity: 0},
        visible: {opacity: 1}
    }

    const animationDelay = 0.3
  return (
    <div className='bg-[#1E2335] px-4 py-4 border-none rounded-[20px] flex items-center justify-center backdrop-blur-sm 
    transition-transform duration-125 ease-in-out hover:scale-125 hover:bg-[#1C63DA]-30 hover:backdrop-blur-sm
    '>
  <motion.div
  ref={ref}
  initial="hidden"
  variants={imageVariants}
  animate={inView ? "visible" : "hidden"}
  custom={index}
  transition={{delay: index * animationDelay}}
  >
    <img
      src={src}
      width='50'
      height='50'
      alt='skill image'
      className='my-auto'
    />
  </motion.div>
  </div>
  )
}

export default SkillDataProvider
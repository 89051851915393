import React from "react";
import {motion} from 'framer-motion'
import { slideInFromLeft, slideInFromRight, slideInFromTop } from '../../utils/motion';
import { SparklesIcon } from '@heroicons/react/24/solid'
import SkillDataProvider from "./SkillDataProvider";
import Html from '../../images/html-5.svg'
import Css from '../../images/css-3.svg'
import Js from '../../images/js.png'
import Php from '../../images/php.png'
import Python from '../../images/python.png'
import Java from '../../images/java.svg'
import Golang from '../../images/golang.svg'
import Kotlin from '../../images/kotlin.png'
import Csharp from '../../images/c-sharp-c.svg'
//Frontend-Skill
import Tailwind from '../../images/tailwind-css.svg'
import Angular from '../../images/angular.svg'
import NextJs from '../../images/nextjs.svg'
import ReactLogo from '../../images/react.svg'
import Vue from '../../images/vue-js.svg'
//Backend and Database
import Django from '../../images/django.png'
import Spring from '../../images/spring.svg'
import Mysql from '../../images/mysql.svg'
import MongoDb from '../../images/mongodb.svg'
import NodeJs from '../../images/node.svg'
import Flask from '../../images/flask.svg'
import "./custom.css"
const Skills = () => {
     const Skill_data = [
        {
          skill_name: "Html 5",
          Image: Html,
        },
        {
          skill_name: "Css",
          Image: Css,
        },
        {
          skill_name: "Java Script",
          Image: Js,
        },
        {
            skill_name: "Php",
            Image: Php,
        },
        {
            skill_name: "python",
            Image: Python,
        },
        {
            skill_name: "java",
            Image: Java,
        },
      ];
      
       const Frontend_skill = [
        {
          skill_name: "Tailwind",
          Image: Tailwind,
        },
        {
          skill_name: "Angular",
          Image: Angular,
          width: 80,
          height: 80,
        },
        {
          skill_name: "React",
          Image: ReactLogo,
          width: 65,
          height: 65,
        },
        {
            skill_name: "Vue",
            Image: Vue,
            width: 65,
            height: 65,
          },
          {
            skill_name: "NextJs",
            Image: NextJs,
            width: 65,
            height: 65,
          },
          {
            skill_name: "golang",
            Image: Golang,
        },     
      ];
      
       const Backend_skill = [
        {
          skill_name: "Node js",
          Image: NodeJs,
          width: 80,
          height: 80,
        },
        {
          skill_name: "Django",
          Image: Django,
          width: 80,
          height: 80,
        },
        {
            skill_name: "Spring",
            Image: Spring,
            width: 80,
            height: 80,
        },
        {
            skill_name: "MongoDb",
            Image: MongoDb,
            width: 80,
            height: 80,
        },
        {
            skill_name: "MySql",
            Image: Mysql,
            width: 80,
            height: 80,
        },
        {
            skill_name: "kotlin",
            Image: Kotlin,
        },   
      ];

      const Other_skill = [
        {
            skill_name: "C#",
            Image: Csharp,
        },  
        {
            skill_name: "Flask",
            Image: Flask,
        },  
      ];
      
      
      
      
      
    return (
      <section
        id="skills"
        className="flex flex-col items-center justify-center gap-3 h-full relative overflow-hidden pb-48 py-20 mt-24"
      >
        <div className='w-full h-auto flex flex-col items-center justify-center'>
        <motion.div
        variants={slideInFromLeft(0.5)}
        className='text-5xl text-white font-medium mt-[10px] text-center mb-[15px] font-english'
        >
            TechStack
        </motion.div>
        <motion.div
        variants={slideInFromRight(0.5)}
        className='cursive text-[20px] text-gray-200 mb-10 mt-[10px] text-center font-thai'
        >
           ภาษาที่ใช้ในการพัฒนา
        </motion.div>
    </div>
        <div className="flex flex-row justify-around sm:justify-center flex-wrap mt-4 gap-5 items-center">
          {Skill_data.map((image, index) => (
            <SkillDataProvider
              key={index}
              src={image.Image}
              width={image.width}
              height={image.height}
              index={index}
            />
          ))}
        </div>
  
        <div className="flex flex-row justify-around sm:justify-center flex-wrap mt-4 gap-5 items-center">
          {Frontend_skill.map((image, index) => (
            <SkillDataProvider
              key={index}
              src={image.Image}
              width={image.width}
              height={image.height}
              index={index}
            />
          ))}
        </div>
        <div className="flex flex-row justify-around sm:justify-center flex-wrap mt-4 gap-5 items-center">
          {Backend_skill.map((image, index) => (
            <SkillDataProvider
              key={index}
              src={image.Image}
              width={image.width}
              height={image.height}
              index={index}
            />
          ))}
        </div>
        <div className="flex flex-row justify-around sm:justify-center flex-wrap mt-4 gap-5 items-center">
          {Other_skill.map((image, index) => (
            <SkillDataProvider
              key={index}
              src={image.Image}
              width={image.width}
              height={image.height}
              index={index}
            />
          ))}
        </div>
      </section>
    );
  };
  
  export default Skills;
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import Header from './component/base/Header';
import Footer from './component/base/Footer';
import Homepage from './component/Homepage';
function App() {
  return (
    <div className="bg-primary3 flex min-h-screen flex-col">
      <Router>
        <Header/>
        <div className='container mx-auto px-12 py-4'>
              <Routes>
                <Route path="/" element={<Homepage />} />
              </Routes>
        </div>
          <Footer />
      </Router>
    </div>
  );
}

export default App;

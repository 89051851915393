import React from 'react'
import { CodeBracketIcon, EyeIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
const ProjectCard = ({
  imgUrl,
  title,
  description,
  gitUrl,
  techStack,
  previewUrl,
}) => {
  return (
    <div id="projects">
      <div className=" md:h-48 rounded-xl relative group  object-fit ">
        <img src={imgUrl} className="md:h-48 rounded-xl" alt="" />
        <div className="overlay items-center justify-center absolute top-0 left-0 w-full h-full bg-[#181818] bg-opacity-0  hidden  group-hover:flex group-hover:rounded-xl group-hover:bg-opacity-80 group-hover:backdrop-blur-sm transition-all duration-500 ease-in">
          {techStack.map((imgSrc, index) => (
            <div
              key={index}
              className="relative"
              style={{ left: `${index * 30}px` }}
            >
              <img
                key={index}
                src={imgSrc}
                width="500"
                height="500"
                className=" top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-10 w-10 text-[#ADB7BE] cursor-pointer group-hover/link:text-white"
                alt={`Tech Stack ${index + 1}`}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="group text-white text-center rounded-b-xl mt-3 bg-[#181818] py-6 px-4 h-60 ">
        <h5 className="text-xl font-semibold mb-2 font-english">{title}</h5>
        <p className="text-[#ADB7BE]  font-thai transition-all duration-300 ease-in-out ">
          {description}
        </p>
      </div>
    </div>
  )
}

export default ProjectCard

import React, { useState } from 'react'
import NavLink from './NavLink'
import { motion } from 'framer-motion'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import MenuOverlay from './MenuOverlay'
import { Link } from 'react-router-dom'

function PricingTable() {
  return (
    <section classNameName='pb-48 mb-24' id="plan">
      <div className="text-center my-10 ">
        <h1 className="text-5xl text-white font-medium mt-[10px] text-center mb-[15px] font-english">Pricing Plan</h1>
        <h4 className="text-gray-600">
          ลูกค้าสามารถเลือก package ได้เลยครับ
        </h4>
      </div>

      <div className="flex flex-col md:flex-row px-2 md:px-0">
        <div
          className="w-full md:w-1/3 relative bg-white/5 border border-white/10 p-5 rounded-2xl overflow-hidden pb-24  mt-5"
          data-aos="fade-up"
          data-aos-delay="0"
          style={{backgroundImage: "linear-gradient(-30deg, rgb(28, 99, 218 , 0.4) 0%, rgb(184, 123, 221,0) 70%)"}}
        >
          <a className="relative block w-fit text-sm bg-white/10  px-4 py-0.5 rounded-full mb-4 text-white">
            Basic
          </a>
          <h1 className="relative text-4xl font-semibold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-secondary1 to-secondary2">Free</h1>
          <h1 className="relative text-3xl font-semibold mb-4 text-white font-thai">เริ่มต้น 0 บาท</h1>
          <p className="relative text-md mb-4 text-white font-thai">สอบถามข้อมูลเกี่ยวกับโปรเจคนักศึกษา</p>
          <div className="relative w-full h-[1px] bg-white/10 mb-4"></div>
          <ul className="relative text-md list-disc list-inside space-y-2">
            <li className="text-md text-white font-thai">ให้คำปรึกษาเกี่ยวกับโปรเจค</li>
            <li className="text-md text-white font-thai">หา solution เพื่อแก้ไขปัญหาให้ลูกค้า</li>
            <li className="text-md text-white font-thai">วางแผนงานให้ลูกค้า</li>
          </ul>
          <Link
          to={"https://lin.ee/yeJcQYf"}
          target="_blank"
          className="text-center absolute text-xl font-thai bottom-5 left-5 w-[calc(100%-40px)] transition border border-secondary1 text-white py-3 rounded-xl hover:bg-[#6449A0] ease-in duration-300"
        >
          Choose Plan
        </Link>
        </div>
        <div
          className="w-full md:w-1/3 relative bg-white/5 border border-white/10 p-5 rounded-2xl overflow-hidden pb-24 md:ml-5 mt-5"
          data-aos="fade-up"
          data-aos-delay="0"
          style={{backgroundImage: "linear-gradient(-30deg, rgb(28, 99, 218 , 0.4) 0%, rgb(184, 123, 221,0) 70%)"}}
        >
          <a className="relative block w-fit text-sm bg-white/10  px-4 py-0.5 rounded-full mb-4 text-white">
            Basic
          </a>
          <h1 className="relative text-4xl font-semibold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-secondary1 to-secondary2 font-english">Fix Bug</h1>
          <h1 className="relative text-3xl font-semibold mb-4 text-white font-thai">เริ่มต้น 1500 บาท</h1>
          <p className="relative text-md mb-4 text-white font-thai">แก้บัค , แก้ปัญหาเว็บไซต์ , แก้รูปแบบเว็บไซต์</p>
          <div className="relative w-full h-[1px] bg-white/10 mb-4"></div>
          <ul className="relative text-md list-disc list-inside space-y-2">
          <li className="text-md text-white font-thai">ให้คำปรึกษาเกี่ยวกับบัคของโปรเจค</li>
            <li className="text-md text-white font-thai">หา solution เพื่อแก้ไขปัญหาให้ลูกค้า</li>
            <li className="text-md text-white font-thai">วางแผนงานให้ลูกค้า</li>
            <li className="text-md text-white font-thai">อธิบายเกี่ยวกับบัคที่เกิดขึ้นและวิธีการที่แก้ไขบัค</li>
            <li className="text-md text-white font-thai">source code ทั้งหมดที่ทำการแก้ไขบัค</li>
          </ul>
          <Link
          to={"https://lin.ee/yeJcQYf"}
          target="_blank"
          className="text-center absolute text-xl font-thai bottom-5 left-5 w-[calc(100%-40px)] transition border border-secondary1 text-white py-3 rounded-xl hover:bg-[#6449A0] ease-in duration-300"
        >
          Choose Plan
        </Link>
        </div>
        <div
          className="w-full md:w-1/3 relative bg-white/5 border border-white/10 p-5 rounded-2xl overflow-hidden pb-24 md:ml-5 mt-5"
          data-aos="fade-up"
          data-aos-delay="0"
          style={{backgroundImage: "linear-gradient(-30deg, rgb(28, 99, 218 , 0.4) 0%, rgb(184, 123, 221,0) 70%)"}}
        >
          <a className="relative block w-fit text-sm bg-white/10  px-4 py-0.5 rounded-full mb-4 text-white">
            Basic
          </a>
          <h1 className="relative text-4xl font-semibold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-secondary1 to-secondary2 font-english ">Project</h1>
          <h1 className="relative text-3xl font-semibold mb-4 text-white font-thai">เริ่มต้น 3500 บาท</h1>
          <p className="relative text-md mb-4 text-white font-thai">ทำโปรเจคจบมหาลัยตั้งแต่ต้น-พรีเซ้นงาน</p>
          <div className="relative w-full h-[1px] bg-white/10 mb-4"></div>
          <ul className="relative text-md list-disc list-inside space-y-2">
          <li className="text-md text-white font-thai">หา solution เพื่อแก้ไขปัญหาให้ลูกค้า</li>
            <li className="text-md text-white font-thai">วางแผนงานให้ลูกค้า</li>
            <li className="text-md text-white font-thai">อธิบายเกี่ยวกับ code flow use-case ทั้งโปรเจค</li>
            <li className="text-md text-white font-thai">source code ทั้งหมด</li>
            <li className="text-md text-white font-thai">สามารถปรึกษาเกี่ยวกับโปรเจคเพื่อใช้ในการ พรีเซ้น หรือ ทำเอกสารได้ 24 ชม.</li>
          </ul>
          <Link
          to={"https://lin.ee/yeJcQYf"}
          target="_blank"
          className="text-center absolute text-xl font-thai bottom-5 left-5 w-[calc(100%-40px)] transition border border-secondary1 text-white py-3 rounded-xl hover:bg-[#6449A0] ease-in duration-300"
        >
          Choose Plan
        </Link>
        </div>
      </div>
    </section>
  )
}

export default PricingTable

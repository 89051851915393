import React, { useState, useRef } from "react";
import ProjectCard from "./ProjectCard";
import ProjectTag from "./ProjectTag";
import { motion, useInView } from "framer-motion";
import Project1 from "../../images/Project1.png"
import Project2 from "../../images/Project2.png"
import Project3 from "../../images/Project3.png"

import ReactLogo from "../../images/react.svg"
import Flask from "../../images/flask.svg"
import Mysql from "../../images/mysql.svg"
import NodeJs from "../../images/node.svg"

const ProjectsSection = () => {

    const projectsData = [
        {
          id: 1,
          title: "SoomSiang",
          description: "โปรเจค SoomSiang เป็นโปรเจคที่รวบรวมแอปพลิเคชันสุ่มในเว็บๆเดียว โดยตัวอย่างฟังก์ชัน สุ่มหวย , สุ่มเลขบัตรประชาชน",
          image: Project1,
          techStack: [ReactLogo , Mysql],
          tag: ["All", "Web"],
          gitUrl: "/",
          previewUrl: "/",
        },
        {
          id: 2,
          title: "ChatBot AI",
          description: "โปรเจค แชทบอทเอไอ(Chatbot AI) เป็นโปรเจคที่จะทำการสร้าง แชทบอทขึ้นมาเพื่อตอบรับผู้ป่วยที่เป็นโรคซึมเศร้า หรือ ตรวจสอบว่าตัวเองเสี่ยงที่จะเป็นหรือไม่ รวมถึงให้ข้อมูลต่างๆเกี่ยวกับโรคซึมเศร้าได้",
          image: Project2,
          techStack: [Flask , Mysql],
          tag: ["All", "Web"],
          gitUrl: "/",
          previewUrl: "/",
        },
        {
          id: 3,
          title: "Web Application",
          description: "โปรเจคเว็บแอปพลิเคชันนี้ เป็นเว็บแอปพลิเคชันที่มีระบบการเข้าสู่ระบบ สมัครสมาชิก รวมถึงสามารถ โพสต์ข้อมูลลงฟีดโดยผู้อื่นสามารถเข้ามาไลก์หรือสามารถแสดงความคิดเห็นได้ สามารถรายงานผู้โพสต์ไม่เหมาะสมได้ รวมถึงยังมีระบบแอดมินสามารถเข้าไปจัดการเพิ่มหรือลบโพสต์ได้",
          image: Project3,
          techStack: [ReactLogo,NodeJs , Mysql],
          tag: ["All", "Web"],
          gitUrl: "/",
          previewUrl: "/",
        },
      ];

    const [tag, setTag] = useState("All");
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
  
    const handleTagChange = (newTag) => {
      setTag(newTag);
    };
  
    const filteredProjects = projectsData.filter((project) =>
      project.tag.includes(tag)
    );
  
    const cardVariants = {
      initial: { y: 50, opacity: 0 },
      animate: { y: 0, opacity: 1 },
    };
  
    return (
      <section id="projects" className="pb-48 py-20 mb-24">
<div className="w-full h-auto flex flex-col items-center justify-center">
        <motion.div className="text-5xl text-white font-medium mt-[10px] text-center mb-[15px] font-english">
          Work
        </motion.div>
        <motion.div className="cursive text-[20px] text-gray-200 mb-10 mt-[10px] text-center font-thai">
          ตัวอย่างผลงาน
        </motion.div>
      </div>
        {/* <div className="text-white flex flex-row justify-center items-center gap-2 py-6">
          <ProjectTag
            onClick={handleTagChange}
            name="All"
            isSelected={tag === "All"}
          />
          <ProjectTag
            onClick={handleTagChange}
            name="Web"
            isSelected={tag === "Web"}
          />
          <ProjectTag
            onClick={handleTagChange}
            name="Mobile"
            isSelected={tag === "Mobile"}
          />
        </div> */}
        <ul ref={ref} className="grid md:grid-cols-3 gap-8 md:gap-12">
          {filteredProjects.map((project, index) => (
            <motion.li
              key={index}
              variants={cardVariants}
              initial="initial"
              animate={isInView ? "animate" : "initial"}
              transition={{ duration: 0.2, delay: index * 0.4 }}
            >
              <ProjectCard
                key={project.id}
                title={project.title}
                description={project.description}
                imgUrl={project.image}
                techStack = {project.techStack}
                gitUrl={project.gitUrl}
                previewUrl={project.previewUrl}
              />
            </motion.li>
          ))}
        </ul>
      </section>
    );
  };
  
  export default ProjectsSection;
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './custom.css'; // Import your custom styles if needed
import { motion } from 'framer-motion';
import CustomerBg from '../../images/bg-customer.svg'
// Import Swiper styles
import 'swiper/css';

// Initialize Swiper modules
const CustomPagination = ({ totalSlides, activeIndex, onClick }) => {
    return (
      <div className="flex justify-center text-white mt-4 ">
        {Array.from({ length: totalSlides }).map((_, index) => (
          <div
            key={index}
            className={` cursor-pointer rounded-full mx-2 transition-all duration-300 ease-out ${
              index === activeIndex ? 'bg-[#4F63D9] w-12' : 'bg-white w-4 h-4'
            }`}
            onClick={() => onClick(index)}
          />
        ))}
      </div>
    );
  };

const CustomerReview = () => {
  const [swiperRef, setSwiperRef] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);  
  const reviews = [
    { id: 1, project: 'Web Application',detail:'(Exchange point)', content: '“พี่ตอบแชทเร็วไม่ต้องให้รอนาน เข้าใจลูกค้ามากก ตอนแรกแอบกลัวเพราะพึ่งโดนโกงมา จากร้านอื่น ผ่อนได้ด้วยร้านนี้ดีมาก อธิบายทุกอย่างเวลาถาม เฮงๆรวยๆครับ”.' },
    { id: 2, project: 'Java Application', detail:'(Game Word)',content: '“พี่เขาใจดีแล้วทำงานเร็วมากๆค่ะ🫶🏼 รอบคอบสุดๆให้ดีเทลหรือบรีฟอะไรไปก็คือแก้ไขได้เลย 🙇🏼‍♀️ แล้วตรงเวลาด้วยค่ะ ใครจะจ้างเขียนโค้ดแนะนำค่ะ”.' },
    { id: 3, project: 'Web Application', detail:'(Dashboard Deliverly Food)',content: '“วันนี้ขออวยยศปกม.คนนี้ ทำงานไวมากก ส่ง ui ให้ยังไง หน้าเว็บไซต์คือแบบนั้นเลย ราคาจับต้องได้ แล้วที่สำคัญเราต้องเอาโค้ดไปอธิบายให้อาจารย์ฟัง ข้อนี้ประทับใจสุด เวรี่กื้ดด”.' },
    { id: 4, project: 'Fix Bug',detail:'(Python Django App)', content: '"ทำงานให้ไวมากๆๆเลยเราให้แก้โค้ดให้นิดหน่อยก็กลายเป็นเขียนเพิ่มให้ด้วยราคาไม่แพงเลยเมื่อเทียบกับสิ่งที่ได้แนะนำดีมากๆๆอธิบายละเอียดมากๆๆคือบางเรื่องก็เข้าใจตอนพี่เขาอธิบายเลย"' },
    { id: 5, project: 'Web Application', detail:'(Chat Bot App)',content: '"มารีวิวค่า ทำงานไวมากค่ะ เสร็จก่อนวันที่บอกไปอีกแถมงานดีมากค่ะ ตรงตามที่ต้องการเลย แนะนำเลยคับ เริศมาก👏"' },
  ];

  const handlePrev = () => {
    if (swiperRef) {
      swiperRef.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef) {
      swiperRef.slideNext();
    }
  };

  const handlePaginationClick = (index) => {
    if (swiperRef) {
      swiperRef.slideTo(index);
      updateActiveIndex();
    }
  };

  const updateActiveIndex = () => {
    if (swiperRef) {
      setActiveIndex(swiperRef.activeIndex);
    }
  };

  return (
    <section className="flex flex-col items-center justify-center gap-3 h-full relative overflow-hidden pb-60 mt-24" id="review">
      <div className="w-full h-auto flex flex-col items-center justify-center">
        <motion.div className="text-5xl text-white font-medium mt-[10px] text-center mb-[15px] font-english">
          Customer Review
        </motion.div>
        <motion.div className="cursive text-[20px] text-gray-200 mb-10 mt-[10px] text-center font-thai">
          ความคิดเห็นของลูกค้า
        </motion.div>
      </div>

      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={1}
        centeredSlides={true}
        spaceBetween={30}
        navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }}
        breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        
        onSlideChange={updateActiveIndex}
      >
    {reviews.map((review, index) => (
        <SwiperSlide key={index} className={`${activeIndex === index ? 'bg-white/10 backdrop-blur-sm' :''} border-2 border-gray-400 rounded-lg h-6`}>
    <div className=" rounded-lg font-english-card">
    <div className="text-transparent bg-clip-text bg-gradient-to-r from-secondary1 to-secondary2 text-xl text-left p-4 font-semibold mb-3 font-english">
        Project : {review.project}<br/>
        {review.detail}
        </div>

        <div className="text-md px-4 text-left text-white font-thai">{review.content}</div>
    </div>
  </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom Navigation */}
      <div className='flex sm:flex-row sm:justify-between lg:flex-row lg:justify-between flex-col justify-center h-full sm:w-full lg:w-full z-50'>
        <div className='mx-5'>
        <CustomPagination
        totalSlides={reviews.length}
        activeIndex={activeIndex}
        onClick={handlePaginationClick}
      />
        </div>
        <div className='flex flex-row lg:mt-0 sm:mt-0 mt-10 mx-5'>
        <div className="swiper-custom-button-next group text-[#B06EEE] h-12 w-12  bg-gradient-to-r from-[#B06EEE] to-[#4F63D9] rounded-full p-1 right-0 hover:text-white hover:cursor-pointer mx-5" onClick={handlePrev}>
            <div className='flex rounded-full  h-full sm:w-full lg:w-full items-center justify-center bg-gray-800 group-hover:bg-gradient-to-r from-[#B06EEE] to-[#4F63D9] transition-height duration-300 ease-in-out' id="indiv">
            <ArrowLeftIcon className="h-5 w-5"/>
            </div>
        </div>
        <div className="swiper-custom-button-next group text-[#B06EEE] h-12 w-12  bg-gradient-to-r from-[#B06EEE] to-[#4F63D9] rounded-full p-1 right-0 hover:text-white hover:cursor-pointer " onClick={handleNext}>
            <div className='flex rounded-full  h-full w-full items-center justify-center bg-gray-800 group-hover:bg-gradient-to-r from-[#B06EEE] to-[#4F63D9] transition-height duration-300 ease-in-out' id="indiv">
            <ArrowRightIcon className="h-5 w-5"/>
            </div>
        </div>
        </div>
      </div>
      <img src={CustomerBg} style={{ pointerEvents: 'none' }} className='absolute inset-x-0 bottom-0 top-40 w-full h-full bg-cover z-0 ' alt="" />
    </section>
  );
};

export default CustomerReview;

import React ,  { useState } from "react";
import NavLink from "./NavLink";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import MenuOverlay from "./MenuOverlay";
import { Link } from "react-router-dom";

const navLinks = [
    {
      title: "About",
      path: "about",
    },
    {
      title: "Projects",
      path: "projects",
    },
    {
      title: "Review",
      path: "review",
    },
    {
      title: "Plan",
      path: "plan",
    },
    {
        title:"OurService",
        path:"services"
    }
  ];

function Header() {
   const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <nav className="fixed mx-auto top-0 left-0 right-0 z-10 bg-primary3/50 bg-opacity-100 font-english">
      <div className="flex container lg:py-4 flex-wrap items-center justify-between mx-auto px-4 py-2">
      <div className="mobile-menu block md:hidden">
          {!navbarOpen ? (
            <button
              onClick={() => setNavbarOpen(true)}
              className="flex items-center px-3 py-2 border rounded border-slate-200 text-slate-200 hover:text-white hover:border-white"
            >
              <Bars3Icon className="h-5 w-5" />
            </button>
          ) : (
            <button
              onClick={() => setNavbarOpen(false)}
              className="flex items-center px-3 py-2 border rounded border-slate-200 text-slate-200 hover:text-white hover:border-white"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          )}
        </div>
        <div className="menu hidden md:block md:w-auto" id="navbar">
          <ul className="flex p-4 md:p-0 md:flex-row md:space-x-8 mt-0">
            {navLinks.map((link, index) => (
              <li key={index}>
                <NavLink to={link.path} title={link.title} />
              </li>
            ))}
          </ul>
        </div>
        <Link
          to={"https://lin.ee/yeJcQYf"}
          target="_blank"
          className="bg-primary3 px-5 py-2 border-[#6449A0] border-2 rounded-lg text-xl text-white font-semibold hover:bg-[#6449A0] ease-in duration-300"
        >
          Contact Me
        </Link>
      </div>
      {navbarOpen ? <MenuOverlay links={navLinks} /> : null}
    </nav>
  );
};
  
  export default Header;
  